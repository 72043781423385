import Nav from "react-bootstrap/Nav";
import EventIcon from "@mui/icons-material/Event";
import HomeIcon from "@mui/icons-material/Home";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import SignpostIcon from "@mui/icons-material/Signpost";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import HandshakeIcon from "@mui/icons-material/Handshake";
import SettingsIcon from "@mui/icons-material/Settings";
import LocalHotelIcon from "@mui/icons-material/LocalHotel";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import SidebarNavItem from "./SidebarNavItem";
import ScreenshotMonitorIcon from "@mui/icons-material/ScreenshotMonitor";

const navLinks = [
  { label: "Home", Icon: HomeIcon },
  { label: "OPD", Icon: EventIcon },
  { label: "Billing", Icon: MonetizationOnOutlinedIcon },
  { label: "Patients", Icon: LocalHotelIcon },
  { label: "Facilities", Icon: SignpostIcon },
  { label: "Doctors", Icon: PersonAddAlt1Icon },
  { label: "Settlements", Icon: HandshakeIcon },
  { label: "Rewards", Icon: EmojiEventsOutlinedIcon },
  { label: "Kiosk", Icon: ScreenshotMonitorIcon },
  { label: "Subscriptions", Icon: LocationOnOutlinedIcon },
];

function SidebarNav(props) {
  const userToken = localStorage.getItem("usr_token");
  
  // Check if the token starts with "FO-"
  const isAdmin = userToken && userToken.startsWith("FO-");
  
  // For debugging
  // console.log("User Token:", userToken);
  // console.log("Is Admin:", isAdmin);

  const currentNavLinks = isAdmin 
    ? [...navLinks, { label: "Settings", Icon: SettingsIcon }] 
    : navLinks;

  return (
    <Nav
      as="nav"
      className="flex-column justify-content-center py-2 fw-bold sidebar"
    >
      {currentNavLinks.map((link) => (
        <SidebarNavItem
          key={link.label}
          Icon={link.Icon}
          label={link.label}
          link={link.label.toLowerCase()}
        />
      ))}
    </Nav>
  );
}

export default SidebarNav;