import { useContext, useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { Spinner } from "react-bootstrap";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AuthContext from "../../context/auth-context";
import ModeContext from "../../context/mode-context";
import Toast from "../ui/Toast";
import { rxOneApi, rxOpdApi } from "../../utils/api/api";
import {
  RX_ONE_ENDPOINTS,
  RX_OPD_ENDPOINTS,
} from "../../utils/api/apiEndPoints";
import OwlCarousel from "react-owl-carousel";
import CloseIcon from "@mui/icons-material/Close";
import Payment from "../opd/appointments-form/Payment";
import AppointmentsFormContext from "../../context/appointments-form";
import { AppointmentsFormProvider } from "../../context/appointments-form";
import HospitalsContext from "../../context/hospitals-context";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

import {
  TextField,
  Button,
  Container,
  makeStyles,
  FormControl,
  InputLabel,
  InputAdornment,
  Input,
  MenuItem,
  Select,
  Modal,
  Typography,
  Grid,
  IconButton,
  Box,
  FormHelperText,
} from "@mui/material";

const essentialService = {
  header: {
    icon: AttachMoneyIcon,
    heading: "Essential",
    p1: "Rx Branded - Generic",
    p2: "15% (Per  Patient Appointment*)",
  },
  body: {
    list: [
      "Personal branded - Patient and Doctor Web Apps",
      "Zero Setup Fee & No Installation Required",
      "No Cost for Marketplace and Admin Apps",
      "Scale to One or Many Facilities with Same Brand at No Additional Cost",
      "NABH Health ID Integration",
      "Virtual Receptionist/Front Desk (Shared)**",
    ],
    button: {
      text: "Un-Subscribe",
      handleClick: () => {},
    },
  },
};

const premiumService = {
  header: {
    icon: AddCircleOutlineIcon,
    heading: "Premium",
    p1: "Custom features, Premium Services",
    p2: "Contact Sales: +91-7703934446",
  },
  body: {
    list: [
      "Personal branded – Patient and Doctor Android & IOS Mobile Apps",
      "Pharmacy Module through Pharmacy Partner Program",
      "Lab Module  through Testing Lab Partner program",
      "Custom Marketing Campaign",
      "Custom Patient Charts for Better Diagnosis",
      "Virtual Receptionist/Front Desk (Dedicated)**",
    ],
    button: {
      text: "Get a Quote",
      handleClick: () => {},
    },
  },
};

function Service(props) {
  const { type } = props;
  const { userName } = props;

  const [closeForm, setCloseForm] = useState(false);
  const { currentHospital } = useContext(HospitalsContext);
  const { selectedProfile, selectedDoc, profiles } = useContext(
    AppointmentsFormContext
  );

  const { logout } = useContext(AuthContext);
  const [openModal, setOpenModal] = useState(false);
  const [opensubModal, setOpensubModal] = useState(false);

  const [gettingQuote, setGettingQuote] = useState(false);
  const [toastType, setToastType] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [shouldLogout, setShouldLogout] = useState(false);
  const [plansdata, setPlansdata] = useState("");
  const [prdata, setPrdata] = useState("");
  const [selectedPlan, setSelectedPlan] = useState(null); // State to store selected plan
  const [selectedPlans, setSelectedPlans] = useState(null);
  const [selectedpt, setSelectedpt] = useState(null);
  const [subprice, setSubprice] = useState("");
  const [checkdata, setCheckdata] = useState("");
  const [data, setData] = useState("");
  const Mode = process.env.REACT_APP_MODE;
  const { mode } = useContext(ModeContext);
  const [processingOnlinePayment, setProcessingOnlinePayment] = useState(false);

  const [verifyingRazorpayPayment, setVerifyingRazorpayPayment] =
    useState(false);
  const [subdata, setSubdata] = useState("");
  const { userEmail } = useContext(AuthContext);
  const [showLoader, setShowLoader] = useState(true); // State to control loader visibility
  const navigate =  useNavigate("");
  const Loader = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress />
    </div>
  );
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCloseModal1 = () => {
    setOpensubModal(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(false); // Hide loader after 2 seconds
    }, 2000);

    return () => clearTimeout(timer); // Clear timeout on component unmount
  }, []);

  useEffect(() => {
    if (shouldLogout && !showToast) {
      logout();
    }
  }, [shouldLogout, showToast]);

  const service = type === "essential" ? essentialService : premiumService;

  useEffect(() => {
    const Subplan = async () => {
      try {
        const userToken = localStorage.getItem("usr_token");
        const userSecret = localStorage.getItem("usr_secret");

        const plans = await rxOneApi.get(
          RX_ONE_ENDPOINTS.SUBSCRIPTIONS.DO_SUBSCRIPTION + "/" + userToken,

          { headers: { usr_secret: userSecret } }
        );

        if (plans) {
          setPlansdata(plans.data.records);
          setPrdata(plans.data);
          // setToastMessage(plans.data.message);
          // setShowToast(true);
        }
      } catch (err) {
        console.error("Error fetching plans:", err);
      }
    };

    Subplan();
  }, []);

  const handlePlans = async (plan) => {
    try {
      const userToken = localStorage.getItem("usr_token");
      const userSecret = localStorage.getItem("usr_secret");
      // Check if userSecret is available before making the API call
      if (!userSecret) {
        throw new Error("User secret not found. Please login again.");
      }

      const Subscribeplan = await rxOneApi.get(
        RX_ONE_ENDPOINTS.SUBSCRIPTIONS.PLAN_PRICE +
          "/" +
          userToken +
          "/" +
          plan.plan_name.toLowerCase(),

        { headers: { usr_secret: userSecret } }
      );

      if (Subscribeplan) {
        setSubprice(Subscribeplan.data.plans);
        setData(Subscribeplan.data);
        setSelectedpt(plan); // Set selected plan
        const annualPlan = Subscribeplan.data.plans.find(
          (plan) => plan.plan_name === "ANNUAL"
        );
        if (annualPlan) {
          setSelectedPlans(annualPlan);
        }

        setOpensubModal(true);
      }
    } catch (err) {
      setOpensubModal(false);
      console.error("Error fetching plans:", err);
    }
  };

  const handlePlanClick = (plan) => {
    setSelectedPlan(plan);
    setOpenModal(true);
  };

  const handlePlanSelect = (plan) => {
    setSelectedPlans(plan); // Update selected plan state
  };
  const unicode = "✓"; // Using the check mark character directly
  const unicode1 = "\u20b9";

  const unsuccessfulOnlinePayment = async (razorpayErrorRes) => {
    console.log({ razorpayErrorRes });
  };

  const successfulOnlinePayment = async (razorpayRes, subscriptionId) => {
    setVerifyingRazorpayPayment(true);
    try {
      const userToken = localStorage.getItem("usr_token");
      const userSecret = localStorage.getItem("usr_secret");

      const body = {
        razorpay_payment_id: razorpayRes.razorpay_payment_id,
        razorpay_order_id: razorpayRes.razorpay_order_id,
        razorpay_signature: razorpayRes.razorpay_signature,
        razorpay_error_code: "",
        razorpay_error_description: "",
        razorpay_error_source: "",
        razorpay_error_step: "",
        razorpay_error_reason: "",
        razorpay_error_metadata_order_id: "",
        razorpay_error_metadata_payment_id: "",
      };

      const verifyRes = await rxOneApi.post(
        RX_ONE_ENDPOINTS.SUBSCRIPTIONS.AFTERPAYEMENT_MSG +
          "/" +
          Mode +
          "/" +
          userToken +
          "/" +
          subscriptionId,

        body,
        {
          headers: {
            usr_secret: userSecret,
            "Content-Type": "application/json",
          },
        }
      );

      if (verifyRes) {
        setToastType("success");
        setToastMessage(verifyRes.data.message);
        setOpensubModal(false);
        setShowToast(true);
        setCloseForm(true);
      }
    } catch (error) {
      setToastType("error");
      setToastMessage(error.message);
      setShowToast(true);
    } finally {
      setVerifyingRazorpayPayment(false);
    //  navigate(`/app/home`)
    window.location.reload();
    }
  };

  const handleSubmit = async () => {
    try {
      const userToken = localStorage.getItem("usr_token");
      const userSecret = localStorage.getItem("usr_secret");

      if (!selectedPlans) {
        console.error("Error in handleSubmit: selectedPlans is null");
        return; // Exit function if selectedPlans is null
      }

      // Access selectedPlans properties
      const order_gross_amount = selectedPlans?.plan_mrp_subunits || 0;
      const order_discount = selectedPlans?.plan_discount_subunits || 0;
      const order_gst = selectedPlans?.plan_applicable_gst_subunits || 0;

      // Calculate total amount
      const order_grand_total =
        parseFloat(order_gross_amount) -
        parseFloat(order_discount) +
        parseFloat(order_gst);

      // Construct request body
      const body = {
        service_id: selectedPlans?.service_id,
        plan_id: selectedPlans?.plan_id,
        order_gross_amount,
        order_discount,
        order_gst,
        order_grand_total,
        plan_add_ons_list: [],
      };

      // Make API call
      const Handlesub = await rxOneApi.post(
        RX_ONE_ENDPOINTS.SUBSCRIPTIONS.VERIFY_PAY +
          "/" +
          Mode +
          "/" +
          userToken,
        body,
        {
          headers: {
            usr_secret: userSecret,
            "Content-Type": "application/json",
          },
        }
      );

      if (Handlesub) {
        await processRazorpayPayment(
          Handlesub.data.razorpay_order_id,
          Handlesub.data.subscription_id
        ); // Pass razorpay_order_id and subscription_id
        setSubdata(Handlesub.data);
      }
    } catch (error) {
      setShowToast(true);
      setToastType("error");
      setToastMessage(error?.error?.message || error?.message);
    }
  };

  const processRazorpayPayment = async (razorpayOrderId, subscriptionId) => {
    try {
      const razorpayKey =
        mode === "live" ? "rzp_live_Uy4jKL7ZDdslqp" : "rzp_test_BTj030BoE9feLH";
      const appointmentId = subdata.subscription_id;
      const options = {
        key: razorpayKey, // Enter the Key ID generated from the Dashboard
        amount: subdata.order_amount_subunits * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: subdata.currency,
        name: "RxOne",
        description: "Subscription",
        image:
          "https://rxone-static.s3.ap-south-1.amazonaws.com/images/rx_192.png",
        order_id: razorpayOrderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        prefill: {
          name: subdata.name,
          email: subdata.email,

          contact: subdata.phone,
        },
        notes: {
          reciept: appointmentId,
        },
        theme: {
          color: "#a5d6a7",
        },
        handler: (razorpayRes) => {
          successfulOnlinePayment(razorpayRes, subscriptionId);
        },
        modal: {
          ondismiss: function () {
            setToastType("info");
            setToastMessage("Payment cancelled by the user.");
            setShowToast(true);
          },
        },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.on("payment.failed", unsuccessfulOnlinePayment);

      rzp1.open();
    } catch (error) {
      setShowToast(true);
      setToastType("error");
      setToastMessage(error.message);
    } finally {
      setProcessingOnlinePayment(false);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          borderRadius: "20px",
        }}
      >
        {Array.isArray(plansdata) && plansdata?.length > 0 ? (
          plansdata.map((plan, index) => (
            <div
  className="card"
  key={plan.plan_id}
  style={{
    width: "300px",
    margin: "7px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "49px",
    backgroundColor: prdata.subscribed_plan === plan.plan_name ?   'rgb(244 246 246)': 'transparent',
  }}
>

              <div
                className="card-header"
                style={{
                  textAlign: "center",
                  backgroundColor: "#199a8e",
                  color: "#fff",
                  cursor: "pointer",
                }}
              >
                {plan.plan_name}
              </div>
              <div className="card-body">
                <h3 style={{ textAlign: "center" }}>
                  {plan.plan_monthly_price}
                </h3>
                <p style={{ textAlign: "center" }}>{plan.plan_description}</p>
                <span
                  style={{
                    textDecoration: "underline",
                    color: "#199a8e",
                    fontWeight: "500",
                    textAlign: "center",
                    display: "block",
                    cursor: "pointer",
                  }}
                  onClick={() => handlePlanClick(plan)}
                >
                  Know more
                </span>
                <div>
                  <blockquote className="blockquote mb-0">
                    <ul>
                      {plan &&
                        plan.plan_inclusions &&
                        plan.plan_inclusions.inclusions.map((item, index) => (
                          <li
                            key={index}
                            style={{
                              listStyle: "none",
                              position: "relative",
                              paddingLeft: "40px",
                              fontSize: "16px",
                            }}
                          >
                            <span
                              style={{
                                position: "absolute",
                                left: "0",
                                color: "black",
                                marginLeft: "10px",
                              }}
                            >
                              {unicode}
                            </span>
                            {item}
                          </li>
                        ))}
                    </ul>
                  </blockquote>
                </div>
              </div>
              {prdata.subscribed_plan === plan.plan_name && (

              <TaskAltIcon style={{backgroundColor: "#28e928", color: "#fff", marginLeft: "68px", marginTop: "10px", marginBottom: "-26px", borderRadius: "50%"}}/>
              )}

              {prdata.subscribed_plan === plan.plan_name && (
                <span
                  style={{
                    textAlign: "center",
                    border: "2px solid #bfbfbf",
                    width: "106px",
                    margin: "auto",
                    display: "block",
                    cursor: "pointer",
                    marginBottom: "10px",
                    borderRadius: "10px",
                    backgroundColor: "#bfbfbf",
                    color: "#fff",
                  }}
                >
                  SUBSCRIBED
                </span>
                
              )}
              {prdata.subscribed_plan === plan.plan_name && (
                <p style={{ textAlign: "center", color: "rgb(121 117 117)", fontSize: "14px" }}>
                  Plan Expires On: {prdata?.plan_expires_on}
                </p>
              )}
              {prdata.subscribed_plan !== plan.plan_name && (
                <span
                  onClick={() => handlePlans(plan)}
                  style={{
                    textAlign: "center",
                    border: "2px solid gray",
                    width: "106px",
                    margin: "auto",
                    display: "block",
                    cursor: "pointer",
                    marginBottom: "10px",
                    borderRadius: "10px",
                    pointerEvents: Boolean(prdata.subscribed_plan)
                      ? "none"
                      : "auto",
                    backgroundColor: Boolean(prdata.subscribed_plan)
                      ? "#ccc"
                      : "#fff",
                    color: Boolean(prdata.subscribed_plan) ? "#888" : "black",
                  }}
                  onMouseEnter={(e) => {
                    if (!Boolean(prdata.subscribed_plan)) {
                      e.target.style.backgroundColor = "#199a8e";
                      e.target.style.color = "#fff";
                      e.target.style.border = "2px solid #199a8e";
                    }
                  }}
                  onMouseLeave={(e) => {
                    if (!Boolean(prdata.subscribed_plan)) {
                      e.target.style.backgroundColor = "#fff";
                      e.target.style.color = "black";
                      e.target.style.border = "2px solid gray";
                    }
                  }}
                >
                  Select Plan
                </span>
              )}
            </div>
          ))
        ) : (
          <div></div>
        )}
      </div>

      <Modal
        open={openModal} // Control modal visibility using state
        onClose={handleCloseModal} // Close modal on outside click
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "20px",
            height: "92vh",
          }}
        >
          <CloseIcon
            onClick={handleCloseModal}
            style={{ float: "right", cursor: "pointer" }}
          />
          <Typography
            variant="h5"
            align="center" // Align center
            style={{
              fontWeight: "500",
              marginBottom: "20px",
              padding: "5px",
              border: "1px solid black",
              width: "183px",
              borderRadius: "5px",
              backgroundColor: "#199a8e",
              color: "#fff",
              fontWeight: "700",
              margin: "auto",
              border: "none",
              overflowY: "auto",
            }}
          >
            {selectedPlan && selectedPlan.plan_name}
          </Typography>

          <h2 style={{ textAlign: "center", fontSize: "20px" }}>
            {selectedPlan && selectedPlan.plan_monthly_price}/month
          </h2>
          <Typography variant="h6" style={{ textAlign: "center" }}>
            Plan Features
          </Typography>

          <div
            style={{ maxHeight: "491px", overflowY: "auto", marginTop: "41px" }}
          >
            {selectedPlan &&
              selectedPlan.plan_details &&
              selectedPlan.plan_details.solutions.map((solution, index) => (
                <div
                  key={index}
                  style={{
                    width: "100%",
                    border: "2px solid gray",
                    borderRadius: "27px",
                    marginTop: "10px",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    padding: "20px",
                  }}
                >
                  <h3 style={{ textAlign: "center" }}>{solution.title}</h3>
                  <ul style={{ paddingLeft: 0 }}>
                    {solution.points.map((point, pointIndex) => (
                      <li
                        key={pointIndex}
                        style={{
                          listStyle: "none",
                          position: "relative",
                          paddingLeft: "20px",
                        }}
                      >
                        <span
                          style={{
                            position: "absolute",
                            left: "0",
                            color: "black",
                          }}
                        >
                          {unicode}
                        </span>
                        {point}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
          </div>
        </Box>
      </Modal>

      <Modal
        open={opensubModal} // Control modal visibility using state
        onClose={handleCloseModal1} // Close modal on outside click
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 454,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "20px",
            overflowY: "auto", // Make content scrollable vertically
          }}
        >
          <CloseIcon
            onClick={handleCloseModal1}
            style={{
              float: "right",
              cursor: "pointer",
              marginRight: "25px",
              marginLeft: "-29px",
            }}
          />
          <Typography
            variant="h5"
            align="center" // Align center
            style={{
              fontWeight: "500",
              marginBottom: "20px",
              padding: "5px",
              border: "1px solid black",
              width: "183px",
              borderRadius: "5px",
              backgroundColor: "#199a8e",
              color: "#fff",
              fontWeight: "700",
              margin: "auto",
              border: "none",
              overflowY: "auto",
            }}
          >
            {selectedpt && selectedpt.plan_name}{" "}
          </Typography>
          <div style={{ height: "491px", overflowY: "auto" }}>
            <div className="card" style={{ marginTop: "15px", border: "none" }}>
              {Array.isArray(subprice) && subprice.length > 0 ? (
                subprice.map((plan, index) => (
                  <div
                    key={index}
                    style={{
                      margin: "7px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <input
                      type="radio"
                      name="planSelection"
                      checked={selectedPlans === plan}
                      onChange={() => handlePlanSelect(plan)}
                      className="form-check-input"
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        marginTop: "55px",
                        marginLeft: "13px",
                      }}
                    />
                    <div
                      className="card-body"
                      style={{
                        marginLeft: "54px",
                        textAlign: "left",
                        maxWidth: "400px",
                      }}
                    >
                      <h5
                        className="card-title"
                        style={{ marginBottom: "1.5rem" }}
                      >
                        {plan.plan_name}
                      </h5>
                      <p className="card-text" style={{ marginTop: "-16px" }}>
                        <span style={{ fontWeight: "bold" }}>
                          {plan.plan_offered_price}
                        </span>{" "}
                        {plan.plan_duration}
                      </p>
                      <p
                        style={{
                          width: "138px",
                          float: "inline-end",
                          marginTop: "-39px",
                          background: "#ddd7d7",
                          display: "inline-block",
                          padding: "5px",
                          border: "1px solid black",
                          borderRadius: "5px",
                          color: "black",
                          fontWeight: "700",
                          border: "none",
                        }}
                      >
                        {plan.plan_monthly_price}
                      </p>
                      <p
                        className="card-text"
                        style={{ color: "blue", fontSize: "14px" }}
                      >
                        <span style={{ textDecoration: "line-through" }}>
                          {plan.plan_mrp}
                        </span>{" "}
                        {plan.discount_text}
                      </p>
                      {plan.plan_id === data?.subscribed_plan_id && (
                        <p style={{ marginTop: "-13px", color: "#f20d0d" }}>
                          Plan Expires On: {data?.plan_expires_on}
                        </p>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <p>No plans available</p>
              )}

              <div
                style={{
                  margin: "2px",
                  fontSize: "19px",
                  fontWeight: "600",
                  maxWidth: "377PX",
                }}
              >
                <h5 style={{ marginLeft: "31px" }}>Order Details</h5>
                {selectedPlans && (
                  <>
                    <div style={{ background: "#f1e9e9" }}>
                      <span
                        style={{
                          textAlign: "center",
                          marginTop: "10px",
                          fontWeight: "500",
                          backgroundColor: "rgb(244 239 239)",
                        }}
                      >
                        <p
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span
                            style={{ marginLeft: "31px", fontSize: "16px" }}
                          >
                            RxOne {selectedPlans.plan_name}
                          </span>
                          <span
                            style={{ marginRight: "38px", fontSize: "16px" }}
                          >
                            {unicode1}
                            {selectedPlans.plan_mrp_subunits / 100}
                          </span>
                        </p>
                        <p
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span
                            style={{ marginLeft: "31px", fontSize: "16px" }}
                          >
                            Discount
                          </span>
                          <span
                            style={{ marginRight: "38px", fontSize: "16px" }}
                          >
                            {unicode1}
                            {selectedPlans.plan_discount_subunits / 100}
                          </span>
                        </p>
                        <p
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span
                            style={{ marginLeft: "31px", fontSize: "16px" }}
                          >
                            GST({selectedPlans.plan_applicable_gst_percent})
                          </span>
                          <span
                            style={{ marginRight: "21px", fontSize: "16px" }}
                          >
                            {unicode1}
                            {selectedPlans.plan_applicable_gst_subunits / 100}
                          </span>
                        </p>
                      </span>
                    </div>
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "-22px",
                      }}
                    >
                      <h5 style={{ marginLeft: "31px", marginTop: "21px" }}>
                        Total Amount
                      </h5>
                      <span
                        style={{
                          marginRight: "10px",
                          fontSize: "18px",
                          fontWeight: "700",
                          marginTop: "23px",
                        }}
                      >
                        {unicode1}
                        {(parseFloat(selectedPlans.plan_mrp_subunits) -
                          parseFloat(selectedPlans.plan_discount_subunits) +
                          parseFloat(
                            selectedPlans.plan_applicable_gst_subunits
                          )) /
                          100}
                      </span>
                    </p>
                  </>
                )}
                {selectedPlans && data.show_pay_now_button && (
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleSubmit}
                    style={{
                      maxWidth: "411px",
                      margin: "auto",
                      backgroundColor: "#199a8e",
                      marginBottom: "10px",
                    }}
                  >
                    Pay Now
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      {showToast && (
        <Toast
          type={toastType}
          show={showToast}
          handleToastClose={setShowToast}
        >
          {toastMessage}
        </Toast>
      )}

      {verifyingRazorpayPayment && (
        <div className="whole-screen-backdrop d-flex justify-content-center align-items-center">
          <div className="text-white">
            <Spinner
              as="span"
              animation="border"
              size="lg"
              role="status"
              aria-hidden="true"
              className="m-auto text-white fs-1 d-block"
            />

            <p className="fs-4">Verifying Payment...</p>
          </div>
        </div>
      )}
      {showLoader && <Loader />}
    </>
  );
}

export default Service;
