import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import MeetingRoomOutlinedIcon from "@mui/icons-material/MeetingRoomOutlined";
import PhotoCameraFrontOutlinedIcon from "@mui/icons-material/PhotoCameraFrontOutlined";
import MailIcon from "@mui/icons-material/Mail";
import AddHomeOutlinedIcon from "@mui/icons-material/AddHomeOutlined";
import Avatar from "@mui/material/Avatar";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  tableCellClasses,
} from "@mui/material";

import ModeContext from "../../context/mode-context";
import HospitalsContext from "../../context/hospitals-context";
import Toast from "../ui/Toast";
import { rxOpdApi } from "../../utils/api/api";
import { RX_OPD_ENDPOINTS } from "../../utils/api/apiEndPoints";
import EditPatientprofile from "./appointments-form/EditPatientprofile";
import { styled } from "@mui/material";
// import { RemoveCircleOutline } from '@material-ui/icons';
import { RemoveCircle } from "@mui/icons-material";
import { ArrowBackIos } from "@mui/icons-material";
import { ArrowBackIosOutlined } from "@mui/icons-material";
function AppointmentModal(props) {
  const { show, onHide, appointmentId } = props;

  const { mode } = useContext(ModeContext);
  const { currentHospital } = useContext(HospitalsContext);
  const [showFileTable, setShowFileTable] = useState(false);
  const [appointment, setAppointment] = useState({
    date: "",
    time: "",
    tokenNo: "",
    status: "",
    type: "",
  });
  const [patient, setPatient] = useState({
    id: "",
    name: "",
    gender: "",
    age: "",
    mobile: "",
    email: "",
  });
  const [doctorName, setDoctorName] = useState("");
  const [showCancelBtn, setShowCancelBtn] = useState(false);
  const [loadingAppointmentDetails, setLoadingAppointmentDetails] =
    useState(true);
  const [doesPatientProfileExists, setDoesPatientProfileExists] =
    useState(false);
  const [appointmentLineages, setAppointmentLineages] = useState(null);
  const [case_file_id, setcase_file_id] = useState("");
  const [shouldCloseModal, setShouldCloseModal] = useState(false);
  const [cancellingAppointment, setCancellingAppointment] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [profileFoam, setProfileForm] = useState([]);
  const [editid, setEditid] = useState("");
  const [editIdChanged, setEditIdChanged] = useState(false);
  const [showUploadFiles, setShowUploadFiles] = useState(false);
  const [showAddFileSection, setShowAddFileSection] = useState(false);
  const [fileType, setFileType] = useState("X-ray");
  const [description, setDescription] = useState("");
  const [updatecalmodal, setupdatecalModal] = useState(""); // state to handle refresh when profile is updated in the calendar
  const [selectedFile, setSelectedFile] = useState(null); // New state to store selected file
  const [newfile, setNewfile] = useState("");
  const [deletingFile, setDeletingFile] = useState(null);
  const [isLoadingFiles, setIsLoadingFiles] = useState(false);
  const [caseid, setcaseid] = useState("");
  const [errors, setErrors] = useState({
    file: "",
    fileType: "",
    description: "",
  });

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#00b0f0",
      fontSize: 13,
      color: theme.palette.common.white,
      padding: "12px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      padding: "10px",
      textAlign: "start",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  useEffect(() => {
    if (shouldCloseModal && !showToast) {
      onHide();
    }
  }, [shouldCloseModal, showToast]);

  const fetchPatientdetial = async () => {
    const userKeys = localStorage.getItem("usr_keys");
    const userModeKey = JSON.parse(userKeys)[mode];
    const key = userModeKey[`${mode}_key`];
    const secret = userModeKey[`${mode}_secret`];
    rxOpdApi.setAuthHeaders(key, secret);
    const res = await rxOpdApi.get(
      `${RX_OPD_ENDPOINTS.HOSPITAL.OPD.PATIENT_INFO}/${currentHospital.hos_id}/${editid}`
    );
    if (res) {
      setProfileForm(res.data);
    }
  };

  useEffect(() => {
    fetchPatientdetial();
    setEditIdChanged(false);
  }, [editIdChanged, editid, currentHospital.hos_id]);

  // const newfiles = async () => {
  //   try {
  //     const userKeys = localStorage.getItem('usr_keys');
  //     const userModeKey = JSON.parse(userKeys)[mode];

  //     const key = userModeKey[`${mode}_key`];
  //     const secret = userModeKey[`${mode}_secret`];

  //     rxOpdApi.setAuthHeaders(key, secret);

  //     const files = await rxOpdApi.get(
  //       RX_OPD_ENDPOINTS.HOSPITAL.APPOINTMENT.NEW_FILES +
  //       '/' +
  //       currentHospital.hos_id +
  //       '/' +
  //       appointmentId
  //     );
  //     if (files) {
  //       setNewfile(files?.data);
  //     }
  //   } catch (err) {
  //     console.error('Error fetching new files:', err);
  //   }
  // };
  const validateFields = () => {
    const newErrors = {
      file: !selectedFile ? "Please select a file" : "",
      fileType: !fileType ? "Please select a file type" : "",
      description: !description ? "Please enter a file name" : "",
    };
    setErrors(newErrors);
    return !Object.values(newErrors).some((error) => error !== "");
  };

  const newfiles = async () => {
    const userKeys = localStorage.getItem("usr_keys");
    const userModeKey = JSON.parse(userKeys)[mode];
    const key = userModeKey[`${mode}_key`];
    const secret = userModeKey[`${mode}_secret`];
    rxOpdApi.setAuthHeaders(key, secret);
    const res = await rxOpdApi.get(
      RX_OPD_ENDPOINTS.HOSPITAL.APPOINTMENT.NEW_FILES +
        currentHospital.hos_id +
        "/" +
        case_file_id
    );
    if (res && res.data && res.data.records) {
      setNewfile(res.data.records);
    }
  };

  useEffect(() => {
    const fetchAppointmentDetails = async () => {
      try {
        const userKeys = localStorage.getItem("usr_keys");
        const userModeKey = JSON.parse(userKeys)[mode];

        const key = userModeKey[`${mode}_key`];
        const secret = userModeKey[`${mode}_secret`];

        rxOpdApi.setAuthHeaders(key, secret);
        const appointmentDetailsRes = await rxOpdApi.get(
          RX_OPD_ENDPOINTS.HOSPITAL.APPOINTMENT.FETCH_APPOINTMENT_DETAILS +
            "/" +
            currentHospital.hos_id +
            "/" +
            appointmentId
        );

        const appointmentLineageRes = await rxOpdApi.get(
          RX_OPD_ENDPOINTS.HOSPITAL.APPOINTMENT.FETCH_APPOINTMENT_LINEAGE +
            "/" +
            currentHospital.hos_id +
            "/" +
            appointmentId
        );

        if (appointmentDetailsRes && appointmentLineageRes) {
          setAppointment({
            date: appointmentDetailsRes.data.appointment
              .appointment_date_formatted,
            time: appointmentDetailsRes.data.appointment
              .appointment_time_formatted,
            status: appointmentDetailsRes.data.appointment.app_status,
            tokenNo:
              appointmentDetailsRes.data.appointment.appointment_token_no,
            type: appointmentDetailsRes.data.appointment.app_type,
          });
          setPatient({
            id: appointmentDetailsRes.data.appointment.pt_profile_id,
            name: appointmentDetailsRes.data.appointment.patient_name,
            age: appointmentDetailsRes.data.appointment.patient_age,
            gender: appointmentDetailsRes.data.appointment.patient_gender,
            mobile: appointmentDetailsRes.data.appointment.patient_phone,
            email: appointmentDetailsRes.data.appointment.patient_email,
          });
          setShowCancelBtn(
            appointmentDetailsRes.data.appointment.show_cancel_button
          );
          setDoctorName(appointmentDetailsRes.data.appointment.doc_name);
          setAppointmentLineages(appointmentLineageRes.data.records);
          setcase_file_id(
            appointmentDetailsRes?.data?.appointment?.case_file_id
          );
          setcaseid(appointmentDetailsRes?.data?.appointment?.case_id);
        }
      } catch (error) {
        setShowToast(true);
        setToastType("error");
        setToastMessage(
          error?.message ||
            error?.error?.message ||
            "Something went wrong. Please try later"
        );
        setShouldCloseModal(true);
      } finally {
        setLoadingAppointmentDetails(false);
      }
    };

    fetchAppointmentDetails();
  }, [mode, currentHospital.hos_id, appointmentId, updatecalmodal]);

  const handleCancelAppointment = async () => {};

  const [editPatientForm, setEditPatientForm] = useState(false);

  const handleEditClick = (editid) => {
    setEditid(editid);
    setEditPatientForm(true);
  };

  const handleFileTypeChange = (event) => {
    setFileType(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleFileInputChange = (event) => {
    if (event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
      setDescription(event.target.files[0].name); // Set the file name to the description input
    }
  };

  const UploadFilesSection = () => (
    <div className="mt-3">
      <Button
        variant="outline-primary"
        onClick={() => setShowAddFileSection(true)}
      >
        <span className="me-2">+</span>
        Add Files
      </Button>
      {isLoadingFiles ? (
        <Spinner animation="border" size="sm" />
      ) : (
        (newfile.length > 0 || showFileTable) && <FileTable />
      )}
    </div>
  );

  const handleSaveFile = async () => {
    if (validateFields()) {
      setLoadingAppointmentDetails(true);
    }
    // if (!selectedFile || !fileType || !description) {
    //   setShowToast(true);
    //   setToastType("error");
    //   setToastMessage("Please fill all fields and select a file.");
    //   return;
    // }

    const formData = new FormData();
    formData.append("patient_file", selectedFile);

    try {
      const userKeys = localStorage.getItem("usr_keys");
      const userModeKey = JSON.parse(userKeys)[mode];
      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];
      rxOpdApi.setAuthHeaders(key, secret);

      const encodedFileType = encodeURIComponent(fileType);
      const encodedFileName = encodeURIComponent(selectedFile.name);

      const response = await rxOpdApi.post(
        `${RX_OPD_ENDPOINTS.HOSPITAL.APPOINTMENT?.ADD_FILE}${currentHospital.hos_id}/${appointmentId}/${case_file_id}/${encodedFileType}/${encodedFileName}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data) {
        setLoadingAppointmentDetails(false);

        await newfiles();
        setShowToast(true);
        setToastType("success");
        setToastMessage("File uploaded successfully");
        setShowAddFileSection(false);
        setShowFileTable(true);
      }
    } catch (error) {
      setShowToast(true);
      setToastType("error");
      setToastMessage(
        error.message || "Failed to upload file. Please try again."
      );
    }
  };

  const handleDeletePrompt = async (row, index) => {
    try {
      setDeletingFile(index);

      const userKeys = localStorage.getItem("usr_keys");
      const userModeKey = JSON.parse(userKeys)[mode];
      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];
      rxOpdApi.setAuthHeaders(key, secret);

      const response = await rxOpdApi.delete(
        RX_OPD_ENDPOINTS.HOSPITAL.APPOINTMENT.DELETE_PATIENT_FILE +
          currentHospital.hos_id +
          "/" +
          case_file_id +
          "/" +
          row.file_code
      );

      if (response.data) {
        // Remove the deleted file from the newfile state
        await newfiles();
        setNewfile((prevFiles) => prevFiles.filter((_, i) => i !== index));
        setShowToast(true);
        setToastType("success");
        setToastMessage("File deleted successfully");
      } else {
        throw new Error(response.data.message || "Failed to delete file");
      }
    } catch (error) {
      console.error("Error deleting file:", error);
      setShowToast(true);
      setToastType("error");
      setToastMessage(
        error.message || "Failed to delete file. Please try again."
      );
    } finally {
      setDeletingFile(null);
    }
  };

  const Handleshare = async () => {
    setLoadingAppointmentDetails(true);

    try {
      const userKeys = localStorage.getItem("usr_keys");
      const userModeKey = JSON.parse(userKeys)[mode];
      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];
      rxOpdApi.setAuthHeaders(key, secret);
      const response = await rxOpdApi.put(
        RX_OPD_ENDPOINTS.HOSPITAL.APPOINTMENT.SHARE_FILE +
          currentHospital.hos_id +
          "/" +
          case_file_id
      );

      if (response.data) {
        setLoadingAppointmentDetails(false);
        // Remove the deleted file from the newfile state
        await newfiles();
        setShowToast(true);
        setToastType("success");
        setToastMessage(response.data.message);

      } else {
        throw new Error(response.data.message || "Failed to delete file");
      }
    } catch (error) {
      console.error("Error deleting file:", error);
      setShowToast(true);
      setToastType("error");
      setToastMessage(
        error.message || "Failed to delete file. Please try again."
      );
    }
  };

  const FileTable = () => (
    <>
    
      <TableContainer
        component={Paper}
        style={{ marginBottom: ".5rem", marginTop: "1rem" }}
      >
        <Table sx={{ minWidth: 300 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ textAlign: "center" }}>
                SN
              </StyledTableCell>
              <StyledTableCell style={{ textAlign: "center" }}>
                File Name
              </StyledTableCell>
              <StyledTableCell style={{ textAlign: "center" }}>
                File Type
              </StyledTableCell>
              <StyledTableCell style={{ textAlign: "center" }}>
                Status
              </StyledTableCell>
              <StyledTableCell
                style={{ textAlign: "center" }}
              ></StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {newfile &&
              Array.isArray(newfile) &&
              newfile.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    {index + 1}
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    {row?.file_name || ""}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    style={{ textAlign: "center" }}
                  >
                    {row?.file_type || ""}
                  </StyledTableCell>

                  <StyledTableCell style={{ textAlign: "center" }}>
                    {row?.status ? "SHARED" : "PENDING" || ""}
                  </StyledTableCell>

                  <StyledTableCell style={{ textAlign: "center" }}>
                    <RemoveCircle
                      onClick={() => {
                        handleDeletePrompt(row, index);
                      }}
                      sx={{ fontSize: "15px" }}
                    />{" "}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button style={{ textAlign: "center" }} onClick={Handleshare}>
          Share
        </Button>
      </div>
    </>
  );

  const AddFileSection = () => (
    <>
      <div
        style={{
          backgroundColor: "#dbdbd2",
          width: "107%",
          padding: "10px",
          marginLeft: "-1rem",
        }}
      >
        <div
          className="w-100 d-flex align-items-center justify-content-between justify-content-md-around mt-3"
          style={{ backgroundColor: "#dbdbd2" }}
        >
          <div
            className="d-flex flex-column justify-content-center fw-bold"
            style={{ textAlign: "left" }}
          >
            <span
              className="text-secondary"
              // style={{ fontSize: '1.1rem' }}
            >
              {appointment.date}
            </span>
            <span className=" text-black">{appointment.time}</span>
            <span style={{ fontWeight: "bold" }}>Case ID: {caseid}</span>
            <div className="d-flex flex-column justify-content-center align-items-center text-success">
              <span
                className={`mb-2 ${
                  appointment.status === "Prescription Shared"
                    ? "btn-successss"
                    : appointment.status === "Appointment Completed"
                    ? "btn-successss"
                    : appointment.status === "Confirmed"
                    ? "btn-primaryii"
                    : appointment.status === "Booked"
                    ? "btn-booked"
                    : appointment.status === "cancelled"
                    ? "btn-danger"
                    : "btn-secondary"
                }`}
                style={{
                  padding: "6px 8px",
                  borderRadius: "21px",
                  caretColor: "transparent",
                }}
              >
                {appointment.status}
              </span>
            </div>
          </div>

          <div className="d-flex align-items-center">
            <div className="d-flex flex-column ps-2">
              <span className="fs-5">
                {patient.name}{" "}
                <BorderColorIcon
                  sx={{ color: "#0d6efd" }}
                  onClick={() => handleEditClick(patient.id)}
                />
              </span>

              <span style={{ fontSize: "0.85rem" }}>
                {patient.gender}, {patient.age}
              </span>
              <a
                href={`mailto:${patient.email} `}
                style={{
                  fontSize: "0.85rem",
                  textDecoration: "none",
                }}
              >
                <MailIcon sx={{ fontSize: 20 }} />
                <span style={{ fontSize: "0.85rem" }}>{patient.email}</span>
              </a>

              <a
                href={`tel:${patient.mobile} `}
                style={{ textDecoration: "none" }}
              >
                <LocalPhoneIcon sx={{ fontSize: 20 }} />
                <span style={{ fontSize: "0.85rem" }}>{patient.mobile}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3">
        <span style={{ textAlign: "center" }}>
          Click on upload icon to browse & select file. Select type and edit
          name as per your choice.
        </span>
        <FormControl fullWidth margin="normal" style={{ border: "1px solid" }}>
          <input
            type="file"
            id="file-input"
            style={{ display: "none" }}
            onChange={handleFileInputChange}
          />
          <label htmlFor="file-input">
            <Button
              variant="contained"
              component="span"
              startIcon={<CloudUploadIcon />}
              fullWidth
              style={{ marginBottom: "16px" }}
            >
              Add Case Files
            </Button>
            <DriveFolderUploadIcon
              style={{ float: "inline-end", margin: "16px" }}
            />
          </label>
        </FormControl>
        {errors.file && (
          <div style={{ color: "red", marginTop: "-8px", marginBottom: "8px" }}>
            {errors.file}
          </div>
        )}

        <FormControl fullWidth margin="normal">
          <InputLabel id="file-type-label">File Type</InputLabel>
          <Select
            labelId="file-type-label"
            id="file-type-select"
            value={fileType}
            label="File Type"
            onChange={handleFileTypeChange}
          >
            <MenuItem value="prescription">Prescription</MenuItem>
            <MenuItem value="labReport">Lab Report</MenuItem>
            <MenuItem value="xray">X-ray</MenuItem>
            <MenuItem value="other">Other</MenuItem>
          </Select>
        </FormControl>
        {errors.fileType && (
          <div style={{ color: "red", marginTop: "-8px", marginBottom: "8px" }}>
            {errors.fileType}
          </div>
        )}

        <TextField
          fullWidth
          margin="normal"
          id="file-description"
          label="File Name"
          variant="outlined"
          value={description}
          onChange={handleDescriptionChange}
        />
        {errors.description && (
          <div style={{ color: "red", marginTop: "-8px", marginBottom: "8px" }}>
            {errors.description}
          </div>
        )}

        <Button onClick={handleSaveFile}>SAVE</Button>
      </div>
    </>
  );

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="md"
        aria-labelledby="appointment-modal"
        centered
      >
        <Modal.Header closeButton className="border-0 pb-md-0"></Modal.Header>

        <Modal.Body className="pt-0">
          {!loadingAppointmentDetails ? (
            <>
              {!showUploadFiles && !showAddFileSection && (
                <>
                  <div className="w-100 d-flex align-items-center justify-content-between justify-content-md-around">
                    {appointment.tokenNo ? (
                      <div
                        className="rounded-pill bg-success text-white py-1 px-3 fs-5"
                        style={{ outline: "3px solid lightgreen" }}
                      >
                        {appointment.tokenNo}
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <div
                    style={{
                      backgroundColor: "#dbdbd2",
                      width: "107%",
                      padding: "10px",
                      marginLeft: "-1rem",
                    }}
                  >
                    <div
                      className="w-100 d-flex align-items-center justify-content-between justify-content-md-around mt-3"
                      style={{ backgroundColor: "#dbdbd2" }}
                    >
                      <div
                        className="d-flex flex-column justify-content-center fw-bold"
                        style={{ textAlign: "left" }}
                      >
                        <span
                          className="text-secondary"
                          // style={{ fontSize: '1.25rem' }}
                        >
                          {appointment.date}
                        </span>
                        <span className=" text-black">{appointment.time}</span>
                        <span style={{ fontWeight: "bold" }}>
                          Case ID: {caseid}
                        </span>
                        <div className="d-flex flex-column justify-content-center align-items-center text-success">
                          <span
                            className={`mb-2 ${
                              appointment.status === "Prescription Shared"
                                ? "btn-successss"
                                : appointment.status === "Appointment Completed"
                                ? "btn-successss"
                                : appointment.status === "Confirmed"
                                ? "btn-primaryii"
                                : appointment.status === "Booked"
                                ? "btn-booked"
                                : appointment.status === "cancelled"
                                ? "btn-danger"
                                : "btn-secondary"
                            }`}
                            style={{
                              padding: "6px 8px",
                              borderRadius: "21px",
                              caretColor: "transparent",
                            }}
                          >
                            {appointment.status}
                          </span>
                        </div>
                      </div>

                      <div className="d-flex align-items-center">
                        <div className="d-flex flex-column ps-2">
                          <span className="fs-5">
                            {patient.name}{" "}
                            <BorderColorIcon
                              sx={{ color: "#0d6efd" }}
                              onClick={() => handleEditClick(patient.id)}
                            />
                          </span>

                          <span style={{ fontSize: "0.85rem" }}>
                            {patient.gender}, {patient.age}
                          </span>
                          <a
                            href={`mailto:${patient.email} `}
                            style={{
                              fontSize: "0.85rem",
                              textDecoration: "none",
                            }}
                          >
                            <MailIcon sx={{ fontSize: 20 }} />
                            <span style={{ fontSize: "0.85rem" }}>
                              {patient.email}
                            </span>
                          </a>

                          <a
                            href={`tel:${patient.mobile} `}
                            style={{ textDecoration: "none" }}
                          >
                            <LocalPhoneIcon sx={{ fontSize: 20 }} />
                            <span style={{ fontSize: "0.85rem" }}>
                              {patient.mobile}
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-2 w-100 d-flex align-items-center justify-content-between justify-content-md-around mt-3  rounded ">
                    <div
                      className="fs-4 fw-bold text-secondary"
                      style={{ marginLeft: "-1rem" }}
                    >
                      {doctorName}
                    </div>

                    <div className="d-flex align-items-center">
                      {appointment.type === "Walk-In" ? (
                        <MeetingRoomOutlinedIcon
                          style={{ fontSize: "3.5rem" }}
                        />
                      ) : appointment.type !== "Scheduled (In-Person)" ? (
                        <PhotoCameraFrontOutlinedIcon
                          style={{ fontSize: "3.5rem" }}
                        />
                      ) : (
                        <AddHomeOutlinedIcon style={{ fontSize: "3.5rem" }} />
                      )}

                      <div className="d-flex flex-column align-items-center justify-content-center text-black ps-1">
                        <div
                          className="text-center fw-bold"
                          style={{
                            fontSize: "0.80rem",
                            maxWidth: "70px",
                          }}
                        >
                          {appointment.type}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="container appointment-lineage ">
                    <div className="row">
                      <div className="col">
                        <div
                          className="timeline-steps aos-init aos-animate"
                          data-aos="fade-up"
                        >
                          {appointmentLineages.map(
                            (appointmentLineage, idx) => (
                              <div key={idx} className="timeline-step">
                                <div
                                  className="timeline-content"
                                  data-toggle="popover"
                                  data-trigger="hover"
                                  data-placement="top"
                                  title=""
                                  data-content="And here's some amazing content. It's very engaging. Right?"
                                >
                                  <div className="appointment-lineage-time">
                                    {
                                      appointmentLineage.app_status_on.split(
                                        ","
                                      )[0]
                                    }
                                    <br />
                                    {
                                      appointmentLineage.app_status_on.split(
                                        ","
                                      )[1]
                                    }
                                  </div>
                                  <div className="inner-circle"></div>
                                  <p className="h6 text-muted">
                                    {appointmentLineage.app_status}
                                  </p>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {showCancelBtn && (
                    <Button
                      variant="danger"
                      className="w-100 mt-2"
                      onClick={handleCancelAppointment}
                      disabled={cancellingAppointment}
                    >
                      {!cancellingAppointment ? (
                        "Cancel Appointment"
                      ) : (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="mx-auto my-5"
                        />
                      )}
                      Add Case Files
                    </Button>
                  )}

                  {appointment.status !== "Booked" && (
                    <Button
                      onClick={async () => {
                        setIsLoadingFiles(true);
                        setShowUploadFiles(true);
                        await newfiles();
                        setIsLoadingFiles(false);
                      }}
                      className="mt-3"
                      disabled={isLoadingFiles}
                    >
                      {isLoadingFiles ? "Loading..." : "Add Case Files"}
                      </Button>
                  )}
                </>
              )}

              {showUploadFiles && !showAddFileSection && (
                <>
                  <div
                    onClick={() => setShowUploadFiles(false)}
                    className="mt-3"
                  >
                    <ArrowBackIosOutlined
                      style={{ marginTop: "-5rem", cursor: "pointer" }}
                    />
                  </div>
                  <div
                    style={{
                      backgroundColor: "#dbdbd2",
                      width: "107%",
                      padding: "10px",
                      marginLeft: "-1rem",
                    }}
                  >
                    <div
                      className="w-100 d-flex align-items-center justify-content-between justify-content-md-around"
                      style={{ backgroundColor: "#dbdbd2" }}
                    >
                      <div
                        className="d-flex flex-column justify-content-center fw-bold"
                        style={{ textAlign: "left" }}
                      >
                        <span
                          className="text-secondary"
                          // style={{ fontSize: '1.1rem' }}
                        >
                          {appointment.date}
                        </span>
                        <span className=" text-black">{appointment.time}</span>
                        <span style={{ fontWeight: "bold" }}>
                          Case ID: {caseid}
                        </span>
                        <div className="d-flex flex-column justify-content-center align-items-center text-success">
                          <span
                            className={`mb-2 ${
                              appointment.status === "Prescription Shared"
                                ? "btn-successss"
                                : appointment.status === "Appointment Completed"
                                ? "btn-successss"
                                : appointment.status === "Confirmed"
                                ? "btn-primaryii"
                                : appointment.status === "Booked"
                                ? "btn-booked"
                                : appointment.status === "cancelled"
                                ? "btn-danger"
                                : "btn-secondary"
                            }`}
                            style={{
                              padding: "6px 8px",
                              borderRadius: "21px",
                              caretColor: "transparent",
                            }}
                          >
                            {appointment.status}
                          </span>
                        </div>
                      </div>

                      <div className="d-flex align-items-center">
                        <div className="d-flex flex-column ps-2">
                          <span className="fs-5">
                            {patient.name}{" "}
                            <BorderColorIcon
                              sx={{ color: "#0d6efd" }}
                              onClick={() => handleEditClick(patient.id)}
                            />
                          </span>

                          <span style={{ fontSize: "0.85rem" }}>
                            {patient.gender}, {patient.age}
                          </span>
                          <a
                            href={`mailto:${patient.email} `}
                            style={{
                              fontSize: "0.85rem",
                              textDecoration: "none",
                            }}
                          >
                            <MailIcon sx={{ fontSize: 20 }} />
                            <span style={{ fontSize: "0.85rem" }}>
                              {patient.email}
                            </span>
                          </a>

                          <a
                            href={`tel:${patient.mobile} `}
                            style={{ textDecoration: "none" }}
                          >
                            <LocalPhoneIcon sx={{ fontSize: 20 }} />
                            <span style={{ fontSize: "0.85rem" }}>
                              {patient.mobile}
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <UploadFilesSection />
                </>
              )}

              {showAddFileSection && (
                <>
                  <div
                    onClick={() => setShowAddFileSection(false)}
                    className="mt-3"
                  >
                    <ArrowBackIosOutlined
                      style={{ marginTop: "-5rem", cursor: "pointer" }}
                    />
                  </div>
                  <AddFileSection />
                </>
              )}
            </>
          ) : (
            <div className="w-100 h-100 d-flex align-items-center justify-content-center">
              <Spinner
                as="span"
                animation="border"
                size="md"
                role="status"
                aria-hidden="true"
                className="mx-auto my-5"
              />
            </div>
          )}
          {editPatientForm && (
            <EditPatientprofile
              editid={editid}
              data={profileFoam}
              addToExistingProfile={true}
              show={editPatientForm}
              onHide={() => setEditPatientForm(false)}
              setupdatecalModal={setupdatecalModal}
            />
          )}
        </Modal.Body>
      </Modal>

      {showToast && (
        <Toast
          type={toastType}
          show={showToast}
          handleToastClose={() => {
            setShowToast(false);
            if (toastType === "success") {
              setShowFileTable(true);
            }
          }}
        >
          {toastMessage}
        </Toast>
      )}
    </>
  );
}

export default AppointmentModal;
