import Toast from '../ui/Toast';
import React from 'react';
import { Modal, Select, MenuItem, FormControl } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import ModeContext from '../../context/mode-context';
import { RX_OPD_ENDPOINTS } from "../../utils/api/apiEndPoints";
import { useContext } from 'react';
import { rxOpdApi } from "../../utils/api/api";
import { useNavigate } from 'react-router-dom';




const Cashpaymentlink = (props) => {
    const{amount,orderid,category,hos_id} = props
    const [open, setOpen] = useState(true);
    const [selected, setSelected] = useState('');
    const [showToast,setShowToast] = useState('false');
const [toastType,setToastType]=useState();
const[toastMessage,setToastMessage]=useState();
const [errorMessage, setErrorMessage] = useState('');


    const { mode } = useContext(ModeContext);  
    const[message,setMessage]=useState();
const navigate=useNavigate()    
    
const handleClose = () => {
  if (selected === '') {
    setErrorMessage('Please select a payment method');
    setToastType('error');
    setShowToast(true);
  } else {
    postData();
  }
}

    const handlepostclose=()=>{   
   navigate ('/app/billing/Orders');    
    }

    
    const selectionChangeHandler = (event) => {
      setSelected(event.target.value);
    };
    
const postData = async() => {
        //console.log("postdata1",selected)
        // alert("hi");    
      
        try {
          const userKeys = localStorage.getItem("usr_keys");
          const userModeKey = JSON.parse(userKeys)[mode];
      
          const key = userModeKey[`${mode}_key`];
          const secret = userModeKey[`${mode}_secret`];
          
          // const values = formikProps.values.patientProfile;
      
          rxOpdApi.setAuthHeaders(key, secret);
          const res = await rxOpdApi.post(
            RX_OPD_ENDPOINTS.HOSPITAL.OPD.CONFIRM_CASH_PAYMENT_MODE + 
            `/${hos_id}/${category}/${orderid}`
             ,
            {
              payment_mode:`${selected}`
              }
          );
      
      
          if (res) {
       setToastMessage(res.data.message)
      setToastType('success')
      setShowToast("true") 
      setOpen(false);


      console.log("Cash2",toastMessage,toastType,showToast)
            
          }
        } catch (error) {
        setMessage(error.message)
          // console.log("error",error.message);
        } finally {
          
         
        }
      };
      
return (<>
<Modal
 open={open}
 onClose={handleClose}>
<Typography>
    <div style = {{ width:"100%", height:"100%" ,display:"flex", justifyContent:"center" }}>


    
    <FormControl fullWidth style={{ marginTop: 200, marginLeft: 100 , backgroundColor: "white",borderRadius: "2px" , width: 400 ,height:300 }}>
    
<div style={{display:"flex" ,flexDirection:"row-reverse"}}>
    <button style={{width: "50px", backgroundColor: "white", border: "none", height: "20px"  }} type="button" onClick={handlepostclose}>
        <span aria-hidden="true" style={{ color: "black", border: "none",fontSize:"40px" }}>
          &times;
        </span>
      </button>    
      </div>

    <h5 style={{ padding: 7,margin:10, textAlign:"center",}}>Confirm Cash Payment </h5> 
   
    <Select displayEmpty  value={selected} style={{marginLeft:100,width:200 }} onChange={selectionChangeHandler}>
    <MenuItem  value="" >
      <h6 style={{fontSize:"80%",marginLeft:"20px",}}>Select Payment Method</h6>
    </MenuItem>
    <MenuItem style={{marginLeft:20}} value={'UPI'}>UPI</MenuItem>
    <MenuItem style={{marginLeft:20}} value={'Credit Card'}>Credit Card</MenuItem>
    <MenuItem style={{marginLeft:20}} value={'Debit Card'}>Debit Card</MenuItem>
    <MenuItem style={{marginLeft:20}} value={'CASH'}>CASH</MenuItem>
    <MenuItem style={{marginLeft:20}} value={'WALLET'}>WALLET</MenuItem>
    
     </Select>
  <h6 style ={{textAlign:"center", marginTop:50}}>Amount: <b>INR {amount}</b></h6>
  
  <button style={{width:"120px",marginLeft:"150px", borderRadius:"5px", marginTop:"50px", backgroundColor:"#009698",color:"white", border:"1px solid white"}} onClick={handleClose}>
    Confirm </button>
    
    </FormControl>

</div>


</Typography>
</Modal>
 {console.log ("toasttype4 show toast",toastType,showToast,toastMessage)}  
       {(showToast && !open) && (
    <Toast
      type={toastType}
      show={showToast}
      handleToastClose={setShowToast}
      autohide={true}
      autohideDelay={3000}
    >
      {toastMessage}
    </Toast>
    

  )}
    {(!showToast && toastType==="success" ) && handlepostclose()}

  </>
 
  )
}

export default Cashpaymentlink