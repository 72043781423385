import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";

import ModeContext from "../context/mode-context";
import Notification from "../components/home/notification/Notification";
import NotificationModal from "../components/home/notification/NotificationModal";
import Toast from "../components/ui/Toast";
import { rxOneApi, rxOpdApi } from "../utils/api/api";
import { RX_ONE_ENDPOINTS, RX_OPD_ENDPOINTS } from "../utils/api/apiEndPoints";

import referralImg from "../assets/images/static/referral.jpg";

function Home(props) {
  const { mode } = useContext(ModeContext);

  const [gettingNotifications, setGettingNotifications] = useState(true);
  const [userNotifications, setUserNotifications] = useState([]);
  const [toastType, setToastType] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [notificationModalData, setNotificationModalData] = useState(null);
  const [shouldLogout, setShouldLogout] = useState(false);
  const [data, setData] = useState([]);
  const [update, setupdate] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    const fetchCard = async () => {
      const userToken = localStorage.getItem("usr_token");

      rxOneApi.setUserSecretAuthHeaders();
      try {
        const res = await rxOneApi.get(
          RX_ONE_ENDPOINTS.HOME.GET_CARD + "/" + mode + "/" + userToken
        );

        if (res) {
          setData(res?.data);
          setupdate(res?.data?.main_tiles[0]?.updated_on);
        } else {
          throw new Error("Something went wrong. Please try later.");
        }
      } catch (error) {
      } finally {
      }
    };

    fetchCard();
  }, []);

  useEffect(() => {
    const fetchNotifications = async () => {
      setGettingNotifications(true);

      const userToken = localStorage.getItem("usr_token");

      rxOneApi.setUserSecretAuthHeaders();
      try {
        const notificationRes = await rxOneApi.get(
          RX_ONE_ENDPOINTS.NOTIFICATION.GET_NOTIFICATION + "/" + userToken
        );

        if (notificationRes) {
          if (notificationRes.data?.message) {
            throw new Error("logout");
          }

          setUserNotifications(notificationRes.data.notifications);
        } else {
          throw new Error("Something went wrong. Please try later.");
        }
      } catch (error) {
        setShowToast(true);
        setToastType("error");
        setToastMessage("Invalid session. Please login again.");
        setShouldLogout(true);
      } finally {
        setGettingNotifications(false);
      }
    };

    fetchNotifications();
  }, []);

  const handleNotificationBtnClick = async (type, noteId, title) => {
    if (type === "kyc") {
      navigate("/app/kyc");
    }

    if (type === "docadd") {
      navigate("/app/doctors/register");
    }

    if (type === "cashpay") {
      navigate("/app/opd/payments");
    }

    setShowNotificationModal(true);
    setNotificationModalData(type);
    setNotificationModalData({
      type,
      noteId,
      title,
    });
  };

  const handleDeleteNotification = async (noteId) => {
    try {
      const userToken = localStorage.getItem("usr_token");

      rxOneApi.setUserSecretAuthHeaders();
      const deleteNotificationRes = await rxOneApi.delete(
        RX_ONE_ENDPOINTS.NOTIFICATION.DELETE_NOTIFICATION +
          "/" +
          userToken +
          "/" +
          noteId
      );

      if (deleteNotificationRes) {
      } else {
        throw new Error("Something went wrong. Please try later.");
      }
    } catch (error) {
      console.log("Error in deleting notification.", error?.message);
    }
  };

  return (
    <>
      {!gettingNotifications && (
        <>
          {(userNotifications || []).map((n) => (
            <Notification
              key={n.note_id}
              bgColor={n.bg_color_hex_code}
              btn={
                n.button_label
                  ? {
                      text: n.button_label,
                      handleClick: () =>
                        handleNotificationBtnClick(
                          n.button_action.replace("/", ""),
                          n.note_id,
                          n.title
                        ),
                    }
                  : null
              }
              handleCloseClick={() => handleDeleteNotification(n.note_id)}
            >
              {n.note}
            </Notification>
          ))}
        </>
      )}

      <div className="alert d-flex flex-column flex-md-row align-items-md-center shadow mt-3 mx-4 p-0">
        <img
          className="img-fluid referral-img"
          src={referralImg}
          alt="Referral"
        />

        <div className="referral-note px-3 px-md-0">
          <h1 className="h5 d-flex d-md-block align-items-center">
            Help a fellow medical practioner/provider grow using RxOne
          </h1>

          <p>
            Know someone who needs to set up Digital OPD? Receive 5000 Rx Points
            (~ ₹1,000) as Rewards - 100% FREE* per successful referral
          </p>
        </div>

        <Button
          className="text-capitalize my-2 mx-3 referral-btn"
          onClick={() => handleNotificationBtnClick("refer")}
        >
          Refer Now
        </Button>
      </div>
      <div  style={{marginRight:'10px'}} className="d-flex justify-content-end">  
      {update ? <p  className="mx-3  mb-0 text-secondary">Last updated : {update}</p> : null}

      </div>
      <Container as="main" className="w-100  ">
              
        <Row className="my-3   ">
          {data?.main_tiles?.map((item, index) => (
            <>
              <Col
                key={index}
                // xs={12}
                md={3}
                className="shadow p-2 mx-1   border solid my-2"
                style={{ width: "250px" }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{marginLeft:'15px'}}>
                    <p style={{ fontSize: "15px", color: "rgb(77, 154, 166)" }}>
                      {item.title}
                    </p>
                    <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                      {item.value}
                    </p>
                  </div>
                  <div className="w-25 " style={{ alignSelf: "center" }}>
                    <img
                      src={item.icon_url}
                      alt="Icon"
                      width="40"
                      height="40"
                    />
                  </div>
                </div>
              </Col>
            </>
          ))}
        </Row>
      </Container>

      <Container as="main" className="w-100 ">
        <div className="d-flex" style={{ overflowX: "scroll", width: "100%" }}>
          {data?.sub_tiles?.map((item, index) =>
            item.value ? (
              <div
                key={index}
                style={{
                  minWidth: "200px",
                  maxWidth: "200px",
                  height: "70px",
                  background: "#E8E8E8",
                  display: "flex",
                }}
                className="p-1 mx-2 mt-3 border rounded mb-2 "
              >
                <img
                  src={item.icon_url}
                  alt="Icon"
                  width="40"
                  height="40"
                  style={{
                    marginTop: "5%",
                    borderRadius: "50%",
                    background: " #B9D9EB",
                    padding: "7px",
                  }}
                />
                <div style={{ marginLeft: "5px" }}>
                  <p style={{ marginBottom: "0%", fontWeight: "bold" }}>
                    {item.value}
                  </p>
                  <p style={{ fontSize: "12px" }}>{item.title || "___"}</p>
                </div>
              </div>
            ) : null
          )}
        </div>
      </Container>
          </>
  );
}

export default Home;
