import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Dropdown, Row, Spinner } from "react-bootstrap";
import AuthContext from "../../context/auth-context";
import ModeContext from "../../context/mode-context";
import { rxOpdApi } from "../../utils/api/api";
import HospitalsContext from "../../context/hospitals-context";
import Filters from "../home/Filters";
import SelectHospital from "../hospitals/SelectHospital";
import { DataGrid } from "@mui/x-data-grid";
import DateRange from "./../date-range/DateRange";
import RefreshDataBtn from "../refresh-data/RefreshDataBtn";
import { RX_OPD_ENDPOINTS } from "../../utils/api/apiEndPoints";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "@mui/material/Button";
import AppointmentsForm from "./AppointmentsForm";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddIcon from "@mui/icons-material/Add";
import { MenuItem } from "@mui/material";
import { Menu } from "@mui/material";
import { Label } from "./../facilities/common/index";
import { Grid } from "@mui/material";


const startDateVal = new Date();
const endDateVal = new Date();
startDateVal.setDate(startDateVal.getDate() - 3);
endDateVal.setDate(endDateVal.getDate() + 1);

const getYyyyMmDdDate = (date) => {
  const month = date.getMonth() + 1;
  const d = date.getDate();

  const mm = month.toString().length === 1 ? `0${month}` : `${month}`;
  const dd = d.toString().length === 1 ? `0${d}` : `${d}`;

  return `${date.getFullYear()}-${mm}-${dd}`;
};
const getDateString = (date) =>
  `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

function OrdersTab({ openOrderDetails, sendOrderData }) {
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext);
  const { mode } = useContext(ModeContext);
  const [modal, setModal] = useState(false);
  const { isLoading, hospitals, currentHospital } =
    useContext(HospitalsContext);
  // console.log(
  //   "🚀 ~ file: OrdersTab.js:23 ~ OrdersTab ~ currentHospital:",
  //   currentHospital
  // );
  const [aredataLoading, setAredataLoading] = useState(true);

  const [startDate, setStartDate] = useState(startDateVal);
  const [endDate, setEndDate] = useState(endDateVal);
  const [data, setData] = useState([]);

  // console.log("🚀 ~ file: OrdersTab.js:45 ~ OrdersTab ~ data:", data);

  const columns = [
    {
      field: "date",
      headerName: "Date",
      width: 120,
      renderCell: (params) => (
        <div style={{ fontSize: "12px" }}>{params.value}</div>
      ),
    },
    {
      field: "order_number",
      headerName: "Order#",
      renderCell: (params) => (
        <div style={{ fontSize: "12px" }}>{params.value}</div>
      ),
    },
    {
      field: "category",
      headerName: "Category",
      width: 120,
      renderCell: (params) => (
        <div style={{ fontSize: "12px" }}>{params.value}</div>
      ),
    },
    {
      field: "type",
      headerName: "Type",
      width: 70,
      renderCell: (params) => (
        <div style={{ fontSize: "12px" }}>{params.value}</div>
      ),
    },
    {
      field: "customer_name",
      headerName: "Customer Name",
      width: 130,
      renderCell: (params) => (
        <div style={{ fontSize: "12px" }}>{params.value}</div>
      ),
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 120,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (params) => (
        <a
          href={`tel:${params.value}`}
          style={{ textDecoration: "none", fontSize: "12px" }}
        >
          {params.value}
        </a>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      width: 180,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      renderCell: (params) => (
        <a
          href={`mailto:${params.value}`}
          style={{ textDecoration: "none", fontSize: "12px" }}
        >
          {params.value}
        </a>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => (
        <div style={{ fontSize: "12px" }}>{params.value}</div>
      ),
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 70,
      renderCell: (params) => (
        <div style={{ fontSize: "12px" }}>{params.value}</div>
      ),
    },
    // { field: "amount_paid", headerName: "Payment", width: 60 },
  ];


  useEffect(() => {
    const senddate = async () => {
      if (!currentHospital) {
        setAredataLoading(false);
        return;
      }

      const userModeKey = JSON.parse(localStorage.getItem("usr_keys"))[mode];
      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];

      try {
        setAredataLoading(true); // Set loading state to true before API call

        rxOpdApi.setAuthHeaders(key, secret);

        const url =
          RX_OPD_ENDPOINTS.HOSPITAL.OPD.ORDERS_LIST +
          "/" +
          currentHospital.hos_id +
          "/" +
          getYyyyMmDdDate(startDate) +
          "/" +
          getYyyyMmDdDate(endDate);

        const res = await rxOpdApi.get(url);

        if (res) {
          setData(res.data.records);
        } else {
          throw new Error("Something went wrong. Please try later.");
        }
      } catch (error) {
        // Handle errors if necessary
      } finally {
        setAredataLoading(false); // Set loading state to false after API call
      }
    };

    senddate();
  }, [startDate, endDate, currentHospital, mode]);

  
// This function will be called when a row is clicked
const handleRowClick = async (params) => {
  
    navigate(
      `/app/billing/Orders/details?hospitalId=${currentHospital.hos_id}&category=${params.row.category}&orderId=${params.row.order_id}`
    );

   
};
  
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <div
        className="my-4 px-3 py-1"
        style={{
          backgroundColor: "#b3c6e7",
        }}
      >
        Select an order for related actions
      </div>

      <div>
        {/* <Col xs={12} md={6} className="my-3 px-2"> */}
        <Row>
          <Col>
            <SelectHospital type="home" className="" />
          </Col>
          <Col
            xs={12}
            lg={8}
            className="d-flex mt-3 mt-lg-0 align-items-center position-relative"
          >
            <Filters
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
          </Col>
        </Row>
        {/* </Col> */}
        <RefreshDataBtn
          setData={data}
          startDate={startDate}
          endDate={endDate}
          setTableDataLoading={setAredataLoading}
        />

        <Grid container className="mt-4 mx-3">
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <Button
              id="basic-button"
              onClick={handleClick}
              variant="contained"
              endIcon={<KeyboardArrowDownIcon />}
              className="py-0"
              size="small"
            >
              <div
                className="py-2 d-flex align-items-center"
                style={{
                  borderRight: "1px solid white",
                  paddingRight: "10px",
                }}
              >
                <AddIcon /> NEW
              </div>
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() =>
                  navigate(
                    `/app/billing/orders/create?hospitalId=${currentHospital.hos_id}&category=SERVICE`
                  )
                }
              >
                <Label>Service Order</Label>
              </MenuItem>
              <MenuItem onClick={() => setModal(true)}>
                <Label>Appointment Order</Label>
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </div>
  
      <div style={{ height: 400, width: "100%" }}>
      {aredataLoading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background: "rgba(255, 255, 255, 0.7)",
              zIndex: 9999,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
                        <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
      )}
        <DataGrid
          rows={data}
          getRowId={(row) => row.order_id}
          columns={columns}
          onRowClick={handleRowClick}
        />
        {modal && (
          <AppointmentsForm show={modal} onHide={() => setModal(false)} />
        )}
      </div>
    </>
  );
}

export default OrdersTab;
